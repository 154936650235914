
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import CallCenter from '@/store/entities/OZONE/callCenter'
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})
export default class EditCallCenter extends AbpBase {
  name: 'edit-call-center'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  callCenter: CallCenter = new CallCenter()
  title: String = ''
  disabled: boolean = false

  created() {}
  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'callCenter/update',
          data: this.callCenter
        })
      } else {
        await this.$store.dispatch({
          type: 'callCenter/create',
          data: this.callCenter
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    if (this.editing) {
      this.callCenter = Util.extend(true, {}, this.$store.state.callCenter.editCallCenter)
    }
    if (this.editing) {
      this.title = 'Editar Call Center'
    } else {
      this.title = 'Crear nuevo Call Center'
    }
    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.callCenterForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.callCenter = new CallCenter()
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.Vozitel.Edit', 'OZONE.Vozitel.Create', 'OZONE.Vozitel.Delete')
  }

  callCenterRule = {
    path: {
      required: { required: true, min: 2 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Path')),
      placeholder: 'Ruta',
      trigger: 'blur'
    },
    nombre: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Name')),
      placeholder: this.L('Name'),
      trigger: 'blur'
    }
  }
}
