
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditCallCenter from './edit-CallCenter.vue'
import PeopleFind from '../../../components/People/people-find.vue'

class PageCallCenterRequest extends PageRequest {
  keyword: string = ''
}

@Component({
  components: { EditCallCenter, PeopleFind }
})
export default class CallCenter extends AbpBase {
  pagerequest: PageCallCenterRequest = new PageCallCenterRequest()

  createModalShow: boolean = false
  editModalShow: boolean = false
  findModalShow: boolean = false

  searchDefinition: {}[] = [
    {
      fieldName: 'Busqueda',
      fieldType: 'text',
      fieldBind: 'keyword',
      value: ''
    }
  ]

  pagination: any = {}

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    await this.getpage()
    this.findModalShow = false
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('callCenter/setCurrentPage', pagination.current)
    await this.getpage()
  }
  get list() {
    console.log(this.$store.state.callCenter.list)
    return this.$store.state.callCenter.list
  }
  get loading() {
    return this.$store.state.callCenter.loading
  }
  editRow(item, index, button) {
    this.$store.commit('callCenter/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar este Call Center?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'callCenter/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  find() {
    this.findModalShow = true
  }

  hide() {
    this.findModalShow = false
  }
  edit() {
    this.editModalShow = true
  }
  create() {
    this.createModalShow = true
  }
  pageChange(page: number) {
    this.$store.commit('callCenter/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('callCenter/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'callCenter/getAll',
      data: this.pagerequest
    })
  }
  get pageSize() {
    return this.$store.state.callCenter.pageSize
  }
  get totalCount() {
    return this.$store.state.callCenter.totalCount
  }
  get currentPage() {
    return this.$store.state.callCenter.currentPage
  }
  set currentPage(page) {
    this.$store.commit('callCenter/setCurrentPage', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Name'),
      dataIndex: 'nombre'
    },
    {
      title: 'Ruta',
      dataIndex: 'path'
    },

    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]
  async created() {
    this.getpage()
  }
}
